import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Layout from "../../components/Layout"
import ThankYou from "../../components/ThankYou"

const ThankYouPage = () => {
  const data = useStaticQuery(graphql`
  query ThankYouFrenchQuery {
    thank_you: datoCmsThankYou(locale: { eq: "fr" }) {
      ...ThankYou
    }
  }
  `)

  return (
    <Layout transparentHeader hideNewsletter>
      <HelmetDatoCms
        htmlAttributes={{
          lang: data.thank_you.locale,
        }}
        seo={data.thank_you.seoMetaTags}
      />
      <ThankYou data={data.thank_you} />
    </Layout>
  )
}

export default ThankYouPage
